/* Global styles */

html, body, app-root {
  height: 100%;
  min-height: 100%;
}

body {
  margin-bottom: 0;
  margin-top: 0;
}

/* Container (extends bootstrap's container class) */
.container {
  padding-top: 10px;
}

/* Section header in the forms */
.section-header {
  padding: 5px;
  font-size: 18px;
  color: #5c0b97;
}

.subsection-header {
  padding: 5px;
  font-size: 16px;
  color: #303030;
}

/* Expand the height of a div inside a col, then vertically align all elements inside that div */
.vert-align {
  height: 100%;
}

.vert-align * {
  top: 50%;
  position: relative;
}

/* Font awesome icons to the left of texts */
label svg, a svg, button svg {
  margin-right: 5px;
}

/* Splitter class for hr elements. Splits a section */
.splitter {
  margin: 30px 0;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

a {
  text-decoration: none;
}

.clickable:hover {
  cursor: pointer;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  background:#fff;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fs-14 {
  font-size: 14px;
}

.fs-13 {
  font-size: 13px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-10 {
  font-size: 10px;
}

.fs-9 {
  font-size: 9px;
}

.basic-info .col-5 img {
  max-width: 100%;
  max-height: 100%;
  height: 300px;
  width: auto !important;
}

/* Matching styles */

$primary: #ff7a59;

.custom-tooltip .tooltip-inner {
  background-color: $primary;
}
.custom-tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: $primary;
}
.custom-tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: $primary;
}
.custom-tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $primary;
}
.custom-tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $primary;
}

.right-modal > .modal-dialog {
  position: absolute;
  right: 50px;
  margin-right: 0;
  margin-left: 50px;
}

.right-modal > .modal-dialog > .modal-content {
  top: 50%;
  transform: translateY(-50%) !important;
}
